<template>
  <form
    class="BuilderForm"
    @submit.prevent="onSubmit">
    <InputField
      name="email"
      :placeholder="mixWB('EMAIL')"
      :value="formData.email"
      @on-update='onFieldUpdate' />
    <InputField
      name="fullName"
      :placeholder="mixWB('FULL_NAME')"
      :value="formData.fullName"
      @on-update='onFieldUpdate' />
    <InputField
      name="phoneNumber"
      :placeholder="mixWB('PHONE_NUMBER')"
      :value="formData.phoneNumber"
      @on-update='onFieldUpdate' />
    <InputField
      name="address"
      :placeholder="mixWB('STREET_AND_NUMBER')"
      :value="formData.address"
      @on-update='onFieldUpdate' />
    <InputField
      name="postalCode"
      :placeholder="mixWB('POSTAL_CODE')"
      :value="formData.postalCode"
      @on-update='onFieldUpdate' />
    <InputField
      name="city"
      :placeholder="mixWB('CITY')"
      :value="formData.city"
      @on-update='onFieldUpdate' />

    <!-- Errors -->
    <span
      class="ErrorText"
      v-if="errorText">{{ errorText }}</span>

    <!-- Button -->
    <Button
      type="submit"
      :text="buttonText"
      :isLoading="isLoading" />
  </form>
</template>

<script>
import InputField from '@/components/FormElements/InputField.vue'
import Button from '@/components/Buttons/Button.vue'

export default {
  name: 'BuilderForm',
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    startData: {
      type: Object,
      required: false,
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        fullName: '',
        phoneNumber: '',
        email: '',
        address: '',
        postalCode: '',
        city: '',
      },
      errorText: '',
    }
  },
  watch: {
    startData() {
      this.setStartData()
    },
  },
  methods: {
    onSubmit() {
      this.$emit('form-submit', this.formData)
    },
    onFieldUpdate(field) {
      this.errorText = ''
      this.formData[field.name] = field.value.trim()
    },
    setStartData() {
      if (this.startData && Object.keys(this.startData).length) {
        this.formData.fullName = this.startData.fullName
        this.formData.phoneNumber = this.startData.phoneNumber
        this.formData.email = this.startData.email
        this.formData.address = this.startData.address.address
        this.formData.postalCode = this.startData.address.postalCode
        this.formData.city = this.startData.address.city
      }
    },
  },
  components: {
    InputField,
    Button,
  },
  created() {
    this.setStartData()
  },
}
</script>

<style lang="stylus" scoped>

</style>
