<template>
  <div
    class="PageLoading">
    <div class="Box">
      <Circular :size="'medium'" />
    </div>
  </div>
</template>

<script>
import Circular from '@/components/Progress/Circular.vue'

export default {
  name: 'PageLoading',
  components: { Circular },
}
</script>

<style lang="stylus" scoped>
  .PageLoading
    position absolute
    top 0
    left 0
    z-index 1
    width 100%
    height 100%
    // background-color rgba(#fff, 0.75)
    background-color #fff
    flex-center-children()

  .Box
    box(400px)
</style>
