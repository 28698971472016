<template>
  <div class="TableOfContents">
    <PageTag :pageName="mixWB('TABLE_OF_CONTENTS')" />
    <PageLoading v-if="isPageLoading" />
    <PageActions
      v-if="!isPageLoading && pdfType === 'report'"
      :actions="[
        { text: mixWB('BUILDER'), id: 'builder' },
      ]"
      @builder="onBuilder" />

    <div
      class="InnerPage">
      <h1 class="TableTitle">{{ mixWB('TABLE_OF_CONTENTS') }}</h1>

      <div class="Table">
        <a
          class="Row"
          v-for="section in sections"
          :key="section.id"
          :href="'#' + section.anchor"
          @click.prevent="onRowClick(section.anchor)">
          <div class="SectionNumber">{{ section.id }}.</div>
          <div class="SectionName">{{ section.text }}</div>
          <div class="Dots">
            <div class="Line"/>
          </div>
          <div class="PageNumber">{{ section.pageNumber }}</div>
        </a>
        <br/>
        <a
          class="Row"
          v-for="item in appendices"
          :key="item.id"
          :href="'#' + item.anchor"
          @click.prevent="onRowClick(item.anchor)">
          <div class="SectionName">{{ item.text }}</div>
          <div class="Dots">
            <div class="Line"/>
          </div>
          <div class="PageNumber">{{ item.pageNumber }}</div>
        </a>
      </div>

      <template v-if="pdfType === 'tender'">
        <div class="TenderMilvaTag">
          <span>{{ mixWB('SUPPLIED_BY') }}</span>
          <img
            class="Logo"
            src="@/assets/svg/logos/milva-logo-green-tm.svg" />
        </div>
      </template>

      <div
        v-if="pdfType === 'report'"
        class="ReportMilvaTag">
        <span>{{ mixWB('A') }}</span>
        <img
          class="Logo"
          src="@/assets/svg/logos/milva-logo-green-tm.svg" />
        <span>{{ mixWB('REPORT') }}</span>
      </div>

      <ProjectMetaData v-if="pdfType === 'report'" />
    </div>

    <Footer />
  </div>
</template>

<script>
import PageTag from '@/components/PDF/PageTag.vue'
import PageLoading from '@/components/PDF/PageLoading.vue'
import PageActions from '@/components/PDF/PageActions'
import ProjectMetaData from '@/components/PDF/Report/ProjectMetaData.vue'
import Footer from '@/components/PDF/Footer'
import { scrollTo } from '@/globals/javascript/_util/util'
import {
  getClosestByClass,
  queryAllByClass,
  queryByClass,
} from '@/globals/javascript/_util/find-elements'
import EventBus from '@/EventBus'

export default {
  name: 'TableOfContents',
  props: {
    allPagesLoaded: {
      type: Boolean,
      required: true,
    },
    pdfType: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      sections: [],
      appendices: [],
      isPageLoading: true,
    }
  },
  methods: {
    onRowClick(anchor) {
      const element = document.getElementById(anchor)

      if (!element) {
        return
      }

      scrollTo({ element, offset: -60, duration: 500 })
    },
    onPageUpdated() {
      this.isPageLoading = true
      requestAnimationFrame(() => {
        if (this.allPagesLoaded) {
          this.setAllPageNumbers()
        }
      })
    },
    onBuilder() {
      EventBus.$emit('open-modal', 'builder')
    },
    setAllPageNumbers() {
      let allPageNumbersTagsLast = queryAllByClass('js-page-number')

      const waitForAll = setInterval(() => {
        const allPageNumbersTags = queryAllByClass('js-page-number')

        if (allPageNumbersTags.length !== allPageNumbersTagsLast.length) {
          allPageNumbersTagsLast = allPageNumbersTags
          return
        }
        clearInterval(waitForAll)

        allPageNumbersTags.forEach((pageNumber, index) => {
          pageNumber.innerHTML = index + 2
        })
        this.makeTableOfContent()
        EventBus.$emit('table-of-content-update')
      }, 250)
    },
    makeTableOfContent() {
      const allHeaders = queryAllByClass('js-headline')
      this.sections = allHeaders.reduce((prev, header) => {
        const innerPage = getClosestByClass('js-inner-page', header)
        const outerPage = innerPage.parentElement
        const pageNumber = queryByClass('js-page-number', outerPage)
        const headerParts = header.innerHTML.split('. ')
        const item = {
          id: headerParts[0],
          text: headerParts[1],
          anchor: header.id,
          pageNumber: pageNumber.innerHTML,
        }

        prev.push(item)
        return prev
      }, [])

      const allAppendixHeaders = queryAllByClass('js-headline-appendix')
      this.appendices = allAppendixHeaders.reduce((prev, header) => {
        const innerPage = getClosestByClass('js-inner-page', header)
        const outerPage = innerPage.parentElement
        const pageNumber = queryByClass('js-page-number', outerPage)
        const title = header.innerHTML
        const item = {
          id: title,
          text: title,
          anchor: header.id,
          pageNumber: pageNumber.innerHTML,
        }

        prev.push(item)
        return prev
      }, [])

      this.isPageLoading = false
    },
  },
  components: {
    PageTag,
    PageLoading,
    PageActions,
    ProjectMetaData,
    Footer,
  },
  created() {
    EventBus.$on('page-updated', this.onPageUpdated)
  },
  mounted() {
    const waitForLoad = setInterval(() => {
      if (!this.allPagesLoaded) {
        return
      }

      clearInterval(waitForLoad)
      this.setAllPageNumbers()
    }, 100)
  },
  destroyed() {
    EventBus.$off('page-updated', this.onPageUpdated)
  },
}
</script>

<style lang="stylus" scoped>
  .TableOfContents
    page()

  .InnerPage
    innerPageReport()
    margin-top 120px

  h1
    text-align center
    margin-bottom 20px
    font-weight bold

  .Table
    width 600px
    margin 0 auto 60px
    .Row
      display flex
      padding 5px 0px
      line-height 1
      text-transform uppercase
      .SectionNumber
        width 35px
      .Dots
        flex-grow 2
        padding 13px 5px 0px
        .Line
          border-bottom 1px dashed $color_grey_dark

  .TenderMilvaTag
    flex-center-children-column()
    margin 0 auto 90px
    img
      margin-top 10px
      margin-left 10px
      width 100px

  .ReportMilvaTag
    flex-center-children()
    align-items flex-end
    width 500px
    margin 0 auto 80px
    img
      display block
      height 20px
      width 75px
      margin 0 5px
</style>
