<template>
  <div class="Footer">
    <div class="PageNumber">{{ mixWB('PAGE') }} | <span class="js-page-number">X</span></div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style lang="stylus" scoped>
  .Footer
    position absolute
    bottom 0
    left 0
    width 100%
    height 100px
    display flex
    align-items center
    justify-content flex-end
    padding 0 50px
    .PageNumber
      span
        display inline
</style>
