<template>
  <div class="MissingItem">
    <div
      v-if="isDone"
      class="IconWrap Done">
      <CheckIcon />
    </div>
    <div
      v-else
      class="IconWrap">
      <WarningIcon />
    </div>
    <span class="Text">{{ text }}</span>
    <div class="ButtonWrap">
      <Button
        size="small"
        :text="mixWB('EDIT')"
        :fullWidth="false"
        @button-click="onButtonClick" />
    </div>
  </div>
</template>

<script>
import CheckIcon from '@/assets/svg/check.svg?inline'
import WarningIcon from '@/assets/svg/warning.svg?inline'
import Button from '@/components/Buttons/Button.vue'

export default {
  name: 'MissingItem',
  props: {
    text: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    isDone: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onButtonClick() {
      this.$emit('item-click', this.name)
    },
  },
  components: {
    CheckIcon,
    WarningIcon,
    Button,
  },
}
</script>

<style lang="stylus" scoped>
  .MissingItem
    display block
    display flex
    align-items center
    margin-bottom 10px

  .IconWrap
    box(30px)
    padding 5px
    margin-right 10px
    svg
        fill $color_error
    &.Done
      svg
        fill $color_primary

  .Text
    width 200px

  .ButtonWrap
    display flex
    align-items center
    >>> .ButtonWrap
        margin-bottom 0
</style>
