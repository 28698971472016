<template>
  <div class="BuilderModal">
    <span class="Title">{{ mixWB('BUILDER') }}</span>

    <BuilderForm
      :buttonText="mixWB('SAVE')"
      :isLoading="isButtonLoading"
      :startData="metaData.builder"
      @form-submit="onFormSubmit" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BuilderForm from '@/components/Forms/BuilderForm'

export default {
  name: 'BuilderModal',
  data() {
    return {
      isButtonLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'metaData',
    ]),
  },
  methods: {
    onFormSubmit(builderInfo) {
      this.isButtonLoading = true
      this.$store.dispatch('setBuilderInfo', builderInfo)

      setTimeout(() => {
        this.$emit('pass-to-parent', {
          name: 'builder-updated',
        })
      }, 200)
    },
  },
  components: {
    BuilderForm,
  },

}
</script>

<style lang="stylus" scoped>
  .BuilderModal
    display block

  .Title
    display block
    text-align center
    text-transform uppercase
    font-size 1.25rem
    margin-bottom 20px
</style>
