<template>
  <div class="PageActions">
    <div
      class="ButtonWrap"
      v-for="item in actions"
      :key="item.id">
      <Button
        size="small"
        :text="item.text"
        @button-click="$emit(item.id)" />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Buttons/Button.vue'

export default {
  name: 'PageActions',
  props: {
    actions: {
      type: Array,
      required: true,
    },
  },
  components: {
    Button,
  },
}
</script>

<style lang="stylus" scoped>
  .PageActions
    display block
    position absolute
    top 0
    left calc(100% + 5px)
    @media print
      display none

  .ButtonWrap
    margin-bottom 5px
</style>
