<template>
  <div class="PageTag">
    {{ pageName }}
  </div>
</template>

<script>
export default {
  name: 'PageTag',
  props: {
    pageName: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="stylus" scoped>
  .PageTag
    position absolute
    top 0
    right calc(100% + 5px)
    text-align right
    white-space nowrap
    color $color_grey
    background-color #fff
    padding 5px 10px
    @media print
      display none
</style>
