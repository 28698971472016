<template>
  <div class="ProjectMetaData">
    <div class="Section">
      <span class="SectionTitle">{{ mixWB('DATE_OF_MAPPING') }}</span>
      <span>{{ mixGetDate(metaData.createdAt, 'LL') }}</span>
    </div>
    <div class="Section">
      <span class="SectionTitle">{{ mixWB('SCREENING_PERFORMED_BY') }}</span>
      <span v-if="screener">{{ screener.fullName }}</span>
    </div>
    <div class="Section">
      <span class="SectionTitle">{{ currentAccount.name }}</span>
      <span>{{ currentAccount.contactPerson.email }}</span>
      <span>Tlf: {{ currentAccount.contactPerson.phoneNumber }}</span>
    </div>
    <div
      :class="{ MissingData: !builder || !builder.fullName }"
      class="Section">
      <span class="SectionTitle">{{ mixWB('REQUESTER') }}</span>
      <span v-if="builder">{{ builder.fullName }}</span>
    </div>
    <div class="Section">
      <span class="SectionTitle">{{ mixWB('CASE_NUMBER') }}</span>
      <span>{{ caseNumber }}</span>
    </div>
    <div
      v-if="personalSamplingID"
      class="Section">
      <span class="SectionTitle">{{ mixWB('SAMPLING_ID') }}</span>
      <span>{{ personalSamplingID }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ProjectMetaData',
  computed: {
    ...mapGetters([
      'metaData',
      'caseNumber',
      'personalSamplingID',
      'allUsers',
      'currentScreeningData',
      'currentAccount',
    ]),
    screener() {
      return this.allUsers.find((x) => x.id === this.currentScreeningData.userID)
    },
    builder() {
      return this.metaData.builder || false
    },
  },
}
</script>

<style lang="stylus" scoped>
  .ProjectMetaData
    span
      text-align center
    .Section
      margin-bottom 25px
      &.MissingData
        border 2px solid red
        span
          color red
    .SectionTitle
      font-weight bold
</style>
